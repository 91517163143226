<template>
  <a-layout id="components-layout-demo-fixed">
    <Navibar />
    <a-layout-content :style="{ padding: '0 50px', marginTop: '50px' }">
      <Breadcrumb :item="['Home']"/>
      <div :style="{ background: '#fff', padding: '24px', minHeight: '500px' }">
        <a-alert type="success"
                 show-icon>
          <div slot="message">Welcome~</div>
          <div slot="description" @click="test()">
            最近刚刚把这个网站复活，现在正在建设中！<br>
            由于作者精力原因，网站将不用于SIF的数据统计，需要以往数据的可以加群联系我：485013503。
          </div>
        </a-alert>
        <div v-for="item in this.danmu" :key="'dm_' + item.id">
          <a-comment>
            <template slot="actions">
            </template>
            <a slot="author">{{ item.uname }}</a>
            <a-avatar
                slot="avatar"
                :src="'/api/blive/get_avatar?uid=' + item.uid"
                :alt="item.uname"
            />
            <p slot="content">
              {{ item.msg }}
            </p>
            <a-tooltip slot="datetime" :title="moment(item.receive_time).format('YYYY-MM-DD HH:mm:ss')">
              <span>{{ moment(item.receive_time).fromNow() }}</span>
            </a-tooltip>
          </a-comment>
        </div>
      </div>
    </a-layout-content>
    <a-layout-footer :style="{ textAlign: 'center' }">
      Powered by Vue + Antdv
    </a-layout-footer>
  </a-layout>
</template>

<script>
import Navibar from "@/components/Navibar";
import Breadcrumb from "@/components/Breadcrumb";
import axios from "axios";
import moment from 'moment';

export default {
  name: "Index",
  components: {Breadcrumb, Navibar},
  data() {
    return {
      welcome: 1,
      danmu: [],
      moment: moment
    }
  },
  created() {
    const that = this;
    axios.post("/api/blive/live_dm/0")
    .then((resp) => {
      that.danmu = resp.data.response;
      that.$forceUpdate();
    });
  },
  methods: {
    test() {
      // console.log(this.danmu)
    }
  }
}
</script>

<style scoped>
.ant-comment-inner {
  padding: 0;
}
</style>
